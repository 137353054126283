<template>
  <div class="benefits rounded p-3">
    <div class="mx-3 py-3 border-bottom">
      <h4>{{ $t("accountSettings.label.gettingPaid") }}</h4>
    </div>
    <b-row>
      <b-col class="m-auto text-right"> </b-col>
    </b-row>
    <div class="p-4">
      <b-alert variant="warning" show>
        <b-icon-exclamation-circle-fill class="mr-2" />
        <span
          >Once a buyer confirms the purchase of your vehicle, we pick the
          vehicle from you and pay you immediately. Please select how you would
          you like to receive the payment.</span
        >
      </b-alert>
      <!-- {{ paidList }} -->
      <div v-for="(pay, key) in paidList" :key="key">
        <div class="border rounded p-2 my-2">
          <b-form-checkbox
            switch
            v-model="payMethods"
            :value="pay.Name"
            @change="selectPayMethods('collapse' + pay.Name)"
          >
            {{ pay.Name }}
          </b-form-checkbox>
        </div>

        <b-collapse
          :id="'collapse' + pay.Name"
          class="mt-2"
          accordion="my-payMethods"
        >
          <b-card>
            <section v-for="f in pay.Feilds" :key="f.FeildId">
              <label>{{ f.Name }}</label>
              <b-form-input v-model="form.feildId[f.FeildId]"></b-form-input>
            </section>
            <section class="text-center mt-3">
              <b-button
                variant="outline-danger"
                class="px-5 mr-2"
                :to="{ name: 'GettingPaid' }"
              >
                {{ $t("global.button.cancel") }}</b-button
              ><b-button
                variant="outline-success"
                class="px-5 ml-2"
                @click="savePayment()"
              >
                {{ $t("global.button.save") }}
              </b-button>
            </section>
          </b-card>
        </b-collapse>
      </div>
      <section v-show="errorMessage">
        <b-alert variant="danger" show>{{ errorMessage }}</b-alert>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      paidList: [],
      payMethods: null,
      form: {
        feildId: []
      },
      errorMessage: null
    };
  },
  methods: {
    selectPayMethods(id) {
      this.form = { feildId: [] };
      this.$root.$emit("bv::toggle::collapse", id);
    },
    async gettingPaidList() {
      const ucc = this.$store.getters.userCountryCode,
        ccc = this.$store.getters.country.Code;
      try {
        const response = await axios.post("Basic/GettingPaidList", {
          CountryCode: ucc ? ucc : ccc
        });
        this.paidList = response.data;
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async savePayment() {
      let paymentInfo = [];
      this.form.feildId.forEach((element, key) => {
        if (element) {
          paymentInfo.push({
            FeildId: key,
            Value: element
          });
        }
      });
      try {
        const response = await axios.post("user/GettingPaidSave", paymentInfo);
        console.log(response);
        this.$router.push({ name: "GettingPaid" });
      } catch (error) {
        this.errorMessage = error.response.data;
      }
    }
  },
  beforeMount() {
    this.gettingPaidList();
  }
};
</script>
